import axios from 'axios';
// import config from '@/config';
import qs from 'qs';
// import store from '../store';
// import router from '../router';


axios.defaults.timeout = 5000;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

// axios.defaults.baseURL = config.apiUrl;

// axios.defaults.withCredentials = true

axios.interceptors.response.use(
    (response) => {
        return response;
    }
);

export const get = (params) => {
    const { url, payload, auth } = params;
    let config = {};
    // 负载不为空
    if (payload !== null || payload !== undefined) {
        config = Object.assign({}, config, { params: payload });
    }

    // 需要验证
    if (auth) {
        config = Object.assign({}, config, {
            headers: {
                authorization: ''
            }
        });
    }
    return axios.get(url, config).then(x => x.data).catch((err) => {
    });
};

export const put = (params) => {
    const { url, payload, auth } = params;

    let authHeader = {};
    // 需要验证
    if (auth) {
        authHeader = {
            headers: {
                authorization: ''
            }
        };
    }

    // 去除没用数据包括null
    Object.keys(payload).map((x) => {
        if (payload[x] === null || payload[x] === 'null' || payload[x] === '') {
            delete payload[x];
        }
    });
    return axios.put(url, qs.stringify(payload), authHeader).then(x => x.data).catch((err) => {
        console.log(JSON.stringify(err));
    });
};

export const del = (params) => {
    const { url, payload, auth } = params
    let authHeader = {}
        // 需要验证
    if (auth) {
        authHeader = {
            headers: {
                authorization: ''
            }
        }
    }
    // 去除没用数据包括null
    Object.keys(payload).map((x) => {
        if (payload[x] === null || payload[x] === undefined || payload[x] === "") {
            delete payload[x];
        }
    });

    return axios.delete(url, { data: payload, headers: authHeader.headers }).then(x => x.data)
}

export const post = (params) => {
    const { url, payload, auth } = params;
    let authHeader = {};
    // 需要验证
    if (auth) {
        authHeader = {
            headers: {
                authorization: ''
            }
        };
    }
    // 去除没用数据包括null
    Object.keys(payload).map((x) => {
        if (payload[x] === null || payload[x] === 'null' || payload[x] === '') {
            delete payload[x];
        }
    });
    return axios.post(url, qs.stringify(payload), authHeader).then(x => x.data);
};