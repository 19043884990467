import { post, get } from '../index'

export const portalCarousel = async (param = {}) => {
  const res = await post({
    payload: param,
    url: '/tznly/appVersion/getLastetAppVersion',
    auth: true
  })
  if(res) {
    return res
  } else {
    return
  }
}
export const list = async (param = {}) => {
  const res = await post({
    payload: param,
    url: '/tznly/tzn_gk/app/list',
    auth: true
  })
  if(res) {
    return res
  } else {
    return 
  }
}
export const tzn_gk = async (param = {}) => { //条子泥印象概括文本
  const res = await post({
    payload: param,
    url: '/tznly/tzn_gk/app/layUIPage',
    auth: true
  })
  if(res) {
    return res
  } else {
    return 
  }
}
export const tzn_jtzn = async (param = {}) => { //条子泥印象交通指南文本
  const res = await post({
    payload: param,
    url: '/tznly/tzn_jtzn/app/layUIPage',
    auth: true
  })
  if(res) {
    return res
  } else {
    return 
  }
}
export const tzn_zdsj = async (param = {}) => { //条子泥印象历史回眸
  const res = await post({
    payload: param,
    url: '/tznly/tzn_zdsj/app/layUIPage',
    auth: true
  })
  if(res) {
    return res
  } else {
    return 
  }
}
export const slideshow = async (param = {}) => {
  const res = await post({
    payload: param,
    url: '/tznly/portal_carousel/app/list',
    auth: true
  })
  if(res) {
    return res
  } else {
    return 
  }
}
export const tzn_sdjs = async (param = {}) => { //湿地介绍
  const res = await post({
    payload: param,
    url: '/tznly/tzn_sdjs/app/list',
    auth: true
  })
  if(res) {
    return res
  } else {
    return 
  }
}
export const tzn_efs = async (param = {}) => { //两分水
  const res = await post({
    payload: param,
    url: '/tznly/tzn_efs/app/list',
    auth: true
  })
  if(res) {
    return res
  } else {
    return 
  }
}
export const tzn_sdtm = async (param = {}) => { //湿地探秘区
  const res = await post({
    payload: param,
    url: '/tznly/tzn_sdtm/app/list',
    auth: true
  })
  if(res) {
    return res
  } else {
    return 
  }
}
export const tzn_gcth = async (param = {}) => { //观潮听海区
  const res = await post({
    payload: param,
    url: '/tznly/tzn_gcth/app/list',
    auth: true
  })
  if(res) {
    return res
  } else {
    return 
  }
}
export const tzn_hqxq = async (param = {}) => { //海趣小取
  const res = await post({
    payload: param,
    url: '/tznly/tzn_hqxq/app/list',
    auth: true
  })
  if(res) {
    return res
  } else {
    return 
  }
}
export const tzn_sdsj = async (param = {}) => { //湿地生境：http://117.80.146.163:18080/tznly/tzn_sdsj/app/list
  const res = await post({
    payload: param,
    url: '/tznly/tzn_sdsj/app/list',
    auth: true
  })
  if(res) {
    return res
  } else {
    return 
  }
}


export const tzn_klssAPI = async (param = {}) => { //景区客流量实时接口
  const res = await post({
    payload: param,
    url: '/tznly/passengers_realtime/queryPassengersRealTime',
    auth: true
  })
  if(res) {
    return res
  } else {
    return 
  }
}

export const tzn_tpzsAPI = async (param = {}) => { //首页图片展示接口
  const res = await post({
    payload: param,
    url: '/tznly/homepageImage/app/layUIPage',
    auth: true
  })
  if(res) {
    return res
  } else {
    return 
  }
}

  export const layUIPage = async (param = {}) => { //	玩转条子泥首页图片展示接口
    const res = await post({
      payload: param,
      url: '/tznly/activePicture/app/layUIPage',
      auth: true
    })
    if(res) {
      return res
    } else {
      return 
    }
  }

  export const activeDirectory = async (param = {}) => { //玩转条子泥精彩活动列表
    const res = await post({
      payload: param,
      url: '/tznly/activeDirectory/app/list',
      auth: true
    })
    if(res) {
      return res
    } else {
      return 
    }
  }

  export const basicInformation = async (param = {}) => { //条子泥攻略基本信息
    const res = await post({
      payload: param,
      url: '/tznly/basicInformation/layUIPage',
      auth: true
    })
    if(res) {
      return res
    } else {
      return 
    }
  }

  export const tzn_basic = async (param = {}) => { //条子泥攻略基本信息
    const res = await post({
      payload: param,
      url: '/tznly/tzn_basic/app/layUIPage',
      auth: true
    })
    if(res) {
      return res
    } else {
      return 
    }
  }

  export const hotelAccommodation = async (param = {}) => { //条子泥攻略周边住宿
    const res = await post({
      payload: param,
      url: '/tznly/hotelAccommodation/app/layUIPage',
      auth: true
    })
    if(res) {
      return res
    } else {
      return 
    }
  }

  export const tzn_interactionAPI = async (param = {}) => { //条子泥攻略景区互动
    const res = await post({
      payload: param,
      url: '/tznly/touristMesage/app/saveMesage',
      auth: true,
      headers:{
        "Content-Type": "application/json"
      }
    })
    if(res) {
      return res
    } else {
      return 
    }
  }
  export const travelRecommend = async (param = {}) => { //条子泥攻略行程推荐文章
    const res = await post({
      payload: param,
      url: '/tznly/travelRecommend/app/layUIPage',
      auth: true,
      headers:{
        "Content-Type": "application/json"
      }
    })
    if(res) {
      return res
    } else {
      return 
    }
  }


  export const scenicServiceType = async (param = {}) => { //勺子商城景区服务
    const res = await post({
      payload: param,
      url: '/tznly/scenicServiceType/app/layUIPage',
      auth: true,
      headers:{
        "Content-Type": "application/json"
      }
    })
    if(res) {
      return res
    } else {
      return 
    }
  }

  export const scenicService = async (param = {}) => { //勺子商城景区服务
    const res = await post({
      payload: param,
      url: '/tznly/scenicService/app/layUIPage',
      auth: true,
      headers:{
        "Content-Type": "application/json"
      }
    })
    if(res) {
      return res
    } else {
      return 
    }
  }

  export const aroundService = async (param = {}) => { //勺子商城周边服务
    const res = await post({
      payload: param,
      url: '/tznly/aroundService/app/layUIPage',
      auth: true,
      headers:{
        "Content-Type": "application/json"
      }
    })
    if(res) {
      return res
    } else {
      return 
    }
  }
